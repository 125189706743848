import { axiosInstance } from '../../api/config';
import { store } from '../../redux/store';
import { saveUser } from '../../redux/userSlice';

export const handleLogin = async (initialState, setErr) => {
  try {
    const response = await axiosInstance
      .post(
        `/bdregister/login`,
        {
          bd_id: initialState.bd_id.trim(),
          password: initialState.password,
        },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        const { token } = response.data.data;
        const user = JSON.stringify(response.data.data);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('bd', user);
        store.dispatch(saveUser(response.data.data));
      });
    return response;
  } catch (error) {
    setErr({ global: 'Credentails Not Matching' });
    throw error;
  }
};


export const verifyEmail = async (email_id, setErr) => {
  try {
    const response = await axiosInstance.post(`/bdregister/forgotpasswordemailverify`, { email_id }, { headers: { "Content-Type": "application/json" } });
    return response;
  } catch (error) {
    setErr({ global: "Email Not Matching" });
    throw error;
  }
}

export const verifyOtp = async (email_id, otpforgotPassword, setErr) => {
  try {
    const response = await axiosInstance.post(`/bdregister/forgotpasswordotpverify`, { email_id, otpforgotPassword }, { headers: { "Content-Type": "application/json" } });
    return response;
  } catch (error) {
    setErr({ global: "Otp Not Matching" });
    throw error;
  }
}

export const submitNewPassword = async (email_id, password, setErr) => {
  try {
    const response = await axiosInstance.post(`/bdregister/forgotpasswordotpset`, { email_id, password }, { headers: { "Content-Type": "application/json" } });
    return response;
  } catch (error) {
    setErr({ global: "Password Change Failed" });
    throw error;
  }
}