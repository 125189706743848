import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bd: null,
};

const userSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {
        saveUser: (state, action) => {
            state.bd = action.payload;
        },
        logout: (state) => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("bd");
            state.bd = null;
        },
    },
});

export default userSlice.reducer;
export const { saveUser, logout } = userSlice.actions;