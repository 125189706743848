import React from "react";
import MyUsersMainSection from "../../components/MyUsersComponents/MyUsersMainSection";

const MyUsers = () => {
  return (
    <div className=" w-full h-full px-2 pt-10 sm:p-10 flex flex-col gap-4 relative">
      <MyUsersMainSection/>
    </div>
  );
};

export default MyUsers;
