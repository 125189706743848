import React from "react";
import { useState } from "react";
import { NoDataFound, SearchInput } from "../Common";
import { useCallback } from "react";
import { useEffect } from "react";
// import { BiFilterAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
// import { GoPlusCircle } from "react-icons/go";
import { getSavedUsers } from "../../utils/userUtils/userUtils";
import SavedUsersTable from "./SavedUsersTable";
import SavedUsersModal from "./SavedUsersModal";

const SavedUsersMainSection = () => {
  const user = useSelector((state) => state.userReducer.bd);
  const [searchQuery, setSearchQuery] = useState("");
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [datas, setDatas] = useState([]);
  const [filteredDatas, setFilteredDatas] = useState([]);
  // const [filterData, setFileterData] = useState({});
  // const [openFilter, setOpenFilter] = useState(false);
  const setDatasFn = useCallback(() => {
    getSavedUsers(user?._id)
      .then((res) => {
        setDatas(res?.data?.data);
      })
      .catch((err) => console.log(err));
  }, [user?._id]);
  useEffect(() => {
    setDatasFn();
  }, [setDatasFn]);
    useEffect(() => {
      const filteredData = datas?.filter((data) => {
        const nameMatch = data?.name
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());
        const phoneMatch = data?.phone_number?.toString()
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());
        const emailMatch = data?.email_id
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase());
        return nameMatch || emailMatch || phoneMatch;
      });
      setFilteredDatas(filteredData);
    }, [datas, searchQuery]);
  return (
    <>
      {open && (
        <SavedUsersModal
          setOpen={setOpen}
          user={user}
          editData={editData}
          setDatasFn={setDatasFn}
          setEditData={setEditData}
        />
      )}
      <div className=" flex w-full items-center gap-5 md:gap-0">
        <div className="flex w-fit h-fit text-xl font-semibold flex-shrink-0">
          Saved Users
        </div>
        <div className="flex w-full h-full justify-center items-center">
          <div className="flex w-full md:w-1/2">
            <SearchInput
              placeholder={"Search"}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
      <div className="flex w-full p-3 flex-col">
        <div className="flex w-full justify-between items-center h-fit">
          <span className="font-semibold text-base">Informations</span>
          <div className="flex gap-2">
            {/* <button
              style={{
                background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              }}
              className={` flex text-white text-sm rounded-md gap-2 p-2  items-center `}
              onClick={() => {
                setOpen(true);
              }}
            >
              <span>
                <GoPlusCircle />
              </span>
              <span>User</span>
            </button> */}
            {/* <button
              style={{
                background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
              }}
              className={` flex text-white text-sm rounded-md gap-2 p-2  items-center `}
              onClick={() => {
                setOpenFilter(true);
              }}
            >
              <span>Filter</span>
              <span>
                <BiFilterAlt />
              </span>
            </button> */}
          </div>
        </div>
        {filteredDatas?.length > 0 ? (
          <SavedUsersTable
            datas={filteredDatas}
            setDatasFn={setDatasFn}
            setEditData={setEditData}
            setOpen={setOpen}
          />
        ) : (
          <div className="flex mt-10">
            <NoDataFound data={"users"} />
          </div>
        )}
      </div>
    </>
  );
};

export default SavedUsersMainSection;
