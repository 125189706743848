import React, { useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useForm } from "../../hooks";
import {
  NumberInput,
  SelectInput,
  TextInput,
} from "../Common/FormComponents";
import { toast } from "react-toastify";
import { addUser } from "../../utils/userUtils/userUtils";

const emailPattern = /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i;
const phoneNumberPattern = /^(?!0{10}$)\d{10}$/;

const MyUsersModal = ({
  setOpen,
  editData = null,
  setEditData = () => {},
  setDatasFn = () => { },
  user,
}) => {
  const initialState = {
    bd_id: user?._id,
    name: editData?.name || "",
    phone_number: editData?.phone_number || "",
    email_id: editData?.email_id || "",
    region: editData?.region || "",
    bp: editData?.bp || "",
    sugar: editData?.sugar || "",
    height: editData?.height || "",
    weight: editData?.weight || "",
    spo2: editData?.spo2 || "",
    blood: editData?.blood || "",
    age: editData?.age || "",
    sex: editData?.sex || "",
    adress: editData?.adress || "",
    area: editData?.area || "",
    city: editData?.city || "",
    pincode: editData?.pincode || "",
    diabetic: editData?.diabetic || "",
    hypertenion: editData?.hypertenion || "",
    any_other: editData?.any_other || "",
    grbs: editData?.grbs || "",
    hp: editData?.hp || "",
    bd_name: editData?.bd_name || "",
    location: editData?.location || "",
  };
  const [loading, setLoading] = useState(false);
  const [formData, handleFormData, clearForm] = useForm(initialState);
  const [err, setErr] = useState({ global: "" });
  const handleSubmit = (user_saved = null) => {
    if (!formData.name.trim().length) return setErr({ global: "Invalid Name" });
    if (!emailPattern.test(formData.email_id))
      return setErr({ global: "Invalid Email" });
    if (!phoneNumberPattern.test(formData.phone_number))
      return setErr({ global: "Invalid Phone" });
    setErr({ global: "" });
    if (!editData && user_saved === true) {  
      const confirmed = window.confirm("Are you sure you want to save ?");
      if (!confirmed) return;
    }
    if (!editData && !user_saved) {
      const confirmed = window.confirm("Are you sure you want to register ?");
      if (!confirmed) return;
    }
    setLoading(true);
    addUser(formData, user_saved, editData?._id)
      .then((res) => {
        setDatasFn();
        clearForm();
        setEditData(null);
        setOpen(false);
        if (user_saved === true) {
          toast(`User Saved`, {
            type: "success",
            autoClose: 3000,
          });
        } else {
          toast(`User ${editData ? "Edited" : "Added"}`, {
            type: "success",
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
            const errorMessage = err.response?.data || err.message;
            if (
              errorMessage.includes("E11000 duplicate key error collection")
            ) {
              toast(`Email already registered`, {
                type: "error",
                autoClose: 3000,
              });
            } else {
            if (user_saved === true) {
              toast(`User Saving Failed`, {
                type: "error",
                autoClose: 3000,
              });
            } else {
              toast(`User ${editData ? "Edit Failed" : "Adde Failed"}`, {
                type: "error",
                autoClose: 3000,
              });
            }
            }
          } else {
            toast(`An error occurred. Please try again.`, {
              type: "error",
              autoClose: 3000,
            });
          }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="absolute flex w-full h-fit top-0 left-0 bottom-0 justify-center items-center p-4 ">
      <div
        style={{
          background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
        }}
        className="flex  w-full max-w-screen-md overflow-y-auto max-h-screen  border shadow-lg  rounded-md gap-4 px-4 pb-4 pt-2 flex-col"
      >
        <div className="flex w-full h-fit items-center justify-between font-semibold text-white">
          <span>{"Register User"}</span>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => {
              setOpen(false);
              setEditData(null);
            }}
          >
            <GrFormClose fontSize={16} />
          </button>
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col md:flex-row">
          <TextInput
            formName={"name"}
            name={"Name"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            formName={"email_id"}
            name={"Email"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col md:flex-row">
          <TextInput
            formName={"phone_number"}
            name={"Phone Number"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <SelectInput
            formName={"sex"}
            name={"Sex"}
            items={["Select", "Male", "Female"]}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <NumberInput
            hide={true}
            formName={"age"}
            name={"Age"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col md:flex-row">
          <SelectInput
            formName={"location"}
            name={"Location"}
            items={[
              "Select",
              "Bangalore Central",
              "Bangalore South",
              "Bangalore West",
              "Bangalore East",
              "Bangalore North",
            ]}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <SelectInput
            formName={"blood"}
            name={"Blood"}
            items={["Select", "A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"]}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col md:flex-row">
          <TextInput
            hide={true}
            formName={"height"}
            name={"Height"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            hide={true}
            formName={"weight"}
            name={"Weight"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        <div className="flex w-full h-fit justify-between gap-5 flex-col md:flex-row">
          <TextInput
            hide={true}
            formName={"bp"}
            name={"BP"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
          <TextInput
            hide={true}
            formName={"grbs"}
            name={"GRBS"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div>
        {/* <div className="flex w-full h-fit justify-between gap-5 flex-col md:flex-row">
          <TextInput
            hide={true}
            formName={"location"}
            name={"Location"}
            data={formData}
            handleInput={handleFormData}
            setError={setErr}
          />
        </div> */}
        {err.global && (
          <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
            {err.global}
          </div>
        )}
        <div className="flex w-full h-fit justify-between gap-5">
          <div className=" flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
              onClick={() => {
                setOpen(false);
                  setEditData(null);
              }}
            >
              Cancel
            </button>
          </div>
          <div className="flex flex-col w-full gap-0.5 ">
            <button
              className="w-full border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={loading}
              onClick={() => {
                handleSubmit(false);
              }}
            >
              {"Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyUsersModal;
