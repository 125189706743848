import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { MyUsers, Login, SavedUsers } from './pages';
import { useDispatch } from "react-redux";
import { useCallback, useEffect } from "react";
import { saveUser } from "./redux/userSlice";
import { AuthProtected, UserProtected } from './components/ProtectedRoutes/UserProtected';

function App() {
  const dispatch = useDispatch();
  const saveUserFromLocalStorage = useCallback(() => {
    const bd = JSON.parse(localStorage.getItem("bd"));
    if (bd) {
      dispatch(saveUser(bd));
    }
  }, [dispatch]);
  useEffect(() => {
    saveUserFromLocalStorage();
  }, [saveUserFromLocalStorage]);
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<MyUsers />}/>
        <Route path="/saved" element={<SavedUsers />}/>
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
