export const validateLoginForm = (initialState, setErr) => {
    const idPattern = /^.+$/;
    const passwordPattern = /^.{8,}$/

    if (!initialState.bd_id.trim() || !idPattern.test(initialState.bd_id.trim())) {
        setErr({ global: "Invalid BD Id" });
        return;
    }

    if (!passwordPattern.test(initialState.password)) {
        setErr({ global: "Invalid Password" });
        return;
    }
    return true;
};